import { Note } from "./note";

export function Chord() {

  this.symbol = ''; // eg, 'Bbm7'
  this.note = new Note();  // eg, Bb
  this.extension = ''; // eg, 'm7', excluding slash and slashed bass
  this.isSlashed = false; // true for slashed chord, eg, Bbm7/Db
  this.slashBass = ''; // 'Db' for Bbm7/Db
  
  /**
   * @param chordSymbol string, eg 'Bbm7'
   * @param key optional object of Key, to set the context of the note of the chord
   */
  this.init = function( chordSymbol, key ) {

    let parsed = parse( chordSymbol );
    if ( parsed.ok ) {
      this.symbol = chordSymbol;
      this.note = new Note().init(parsed.note, key);
      this.extension = parsed.extension;
      this.isSlashed = parsed.isSlashed;
      this.slashBass = parsed.slashBass;
    }
    
    return this;
  }

  /**
   * @param toKey object of Key, the target key to transpose to
   * @returns chord object
   */
  this.transpose = function( toKey ) {
    let newChord = new Chord();
    if ( this.symbol == '' ) return newChord;
    if ( toKey.root == '' ) return newChord;
    if ( !this.note.hasContext ) return newChord;

    // transpose note
    let context = this.note.context;
    let newNoteName = '';

    // for note-less slash chord, eg, '/G'
    if ( this.note.symbol == '' ) {
      // keep it as '', cos otherwise code -1 will be treated as the 11th note from the root.
      newNoteName = '';
    } else {
        // if note on scale
      if ( context.indexOnScale > -1 ) {
        // look for the new note on the new key
        newNoteName = toKey.scale[ context.indexOnScale ];
      } else {
        let newNote = toKey.getRootNote().halfStep( context.indexFromRoot, this.note.accidental );
        newNoteName = newNote.symbol;
      }
    }

    // transpose slash bass
    let newSlashBass = '';
    if ( this.isSlashed ) {
      // treat the bass as a chord
      let bassChord = new Chord().init( this.slashBass, context.key );
      let newBassChord = bassChord.transpose( toKey );
      newSlashBass = newBassChord.symbol;
    }

    let newChordSymbol = newNoteName + this.extension;
    // add slash bass
    if ( this.isSlashed ) {
      newChordSymbol += '/' + newSlashBass;
    }

    return newChord.init( newChordSymbol, toKey );
  }

  /**
   * @param symbol string
   */     
  const parse = function( symbol ) {
    let result = {
      ok : false,
      note : '',
      extension : '',
      isSlashed : false,
      slashBass : '',
    }
    if ( symbol.trim() == '' ) return result;

    const regex = /^([A-G][#b]?)?([^\/]*)(\/[A-G][#b]?)?/;
    const found = regex.exec(symbol); // eg ["Bbm7", "Bb", "m7", undefined ], ["Bbm7", "Bb", "m7", "/Db" ]
    if ( found !== null ) {
      result.ok = true;
      result.note = found[1] ? found[1] : ''; // eg 'Bb'
      result.extension = found[2]; // eg 'm7'
      if ( typeof found[3] !== 'undefined' ) {
        result.isSlashed = true;
        result.slashBass = found[3].substring(1);
      }
    }
    return result;
  }

  /**
   * get context of this.note. If context not set, return null
   */
  const getContext = function() {
    if ( this.note.context.key.root == '' ) {
      return null;
    } else {
      return this.note.context;
    }
  }
}