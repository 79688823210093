/**
 * Key signature
 * 
 * properties:
 *  root : 'A', 'Bb', etc...
 *  symbol : 'A', 'Bbm', etc...
 *  quality : (major|minor)
 *
 * remark on minor scale:
 *  some minor scales does not exist when looking up the 6th notes on the practical major scales,
 *  eg, Ab minor. They point to the alternative root note, ie, G# minor
 */

import { Note } from "./note";
import { ScaleLookup as SCALES } from "./scale-lookup";

export function Key() {

  this.root = '';
  this.symbol = '';
  this.quality = '';
  this.scale = []; // array of note strings, ie, a value in SCALES.MAJORS
  this.scales = {}; // { major, minor }

  /**
   * auto correct theoretical scale to practical one, etc, Fb -> E
   * @symbol : string %note% for major scale, or %note%m for minor.
   *         Eg, 'Ab' = Ab major, 'Abm' = Ab minor
   */
  this.init = function( symbol ) {

    if ( typeof symbol === 'undefined' ) return this;

    // parse symbol
    let parsed = parse( symbol );
    // return if invalid
    if ( !parsed.ok ) return this;

    let root = parsed.root;
    let quality = parsed.quality;

    // return if quality not support
    if ( ['major','minor'].indexOf( quality ) == -1 ) return this;

    // find scales and roots, only support major & minor for now
    let majorRoot,
        minorRoot,
        majorScale,
        minorScale;
    switch ( quality ) {
      case 'major': {
        majorRoot = remapMajorRoot( root );
        majorScale = getMajorScale( majorRoot );
        minorScale = convertToMinorScale( majorScale );
        minorRoot = minorScale ? minorScale[0] : undefined;
        break;
      }
      case 'minor': {
        minorRoot = remapMinorRoot( root );
        let info = getMinorScaleInfo( minorRoot );
        minorScale = info.minorScale;
        majorScale = info.majorScale;
        majorRoot = info.majorRoot;
        break;
      }
    }

    // pack up and return
    this.root = root;
    this.symbol = symbol;
    this.quality = quality;
    this.scales = {
      major : majorScale,
      minor : minorScale,
    };
    this.scale = ( quality == 'major' ) ? this.scales.major : this.scales.minor;

    return this;
  };

  this.getRootNote = () => new Note().init( this.root );

  /**
   * @symbol : key symbol. in format %note% for major scale, or %note%m for minor.
   *         Eg, Ab = Ab major, Abm = Ab minor
   */
  const parse = function( symbol ) {
    let result = {
      ok : false,
      symbol: '',
      root: '',
      quality: ''
    }
    const regex = /^([A-G][#b]?)(m)?/;
    const found = regex.exec(symbol); // eg ["Abm", "Ab", "m"]
    if ( found !== null ) {
      result.ok = true;
      result.symbol = symbol;
      result.root = found[1];
      result.quality = ( found[2] == 'm' ) ? 'minor' : 'major';
    }
    return result;
  }

  // assume given root is a valid note
  const getMajorScale = function( root ) {
    let scale = SCALES.MAJORS[ root ];

    // auto correct theoretical scale
    if ( typeof scale == 'string' ) {
      return getMajorScale( scale );
    }

    return scale;
  }

  // assume given root is a valid note
  const getMinorScaleInfo = function( root ) {

    let result = {
      minorScale : undefined,
      majorScale : undefined,
      majorRoot  : undefined,
    }
    let _root = remapMinorRoot( root );

    // look for it on the 6th note on the major scales
    let majorScale_6thNote = Object.entries(SCALES.MAJORS).find(
      x => Array.isArray( x[1] ) && x[1][5] == _root
    );
    // return if not found
    if ( !majorScale_6thNote ) return result;

    // convert found major scale to minor, and return
    return {
      minorScale : convertToMinorScale( majorScale_6thNote ),
      majorScale : majorScale_6thNote,
      majorRoot  : majorScale_6thNote[0]
    };
  }

  /**
   * convert a major scale to minor natural. Eg, C/D/E/F/G/A/B -> A/B/C/D/E/F/G
   * @param majorScale : array of note strings, ie, a value in SCALES.MAJORS
   */
  const convertToMinorScale = function( majorScale ) {
    // copy scale to minor
    let minor = [ ...majorScale ];
    // slice out the last 2 notes, and append back to the front
    minor.unshift( ...minor.splice( -2, 2 ) );

    return minor;
  };

  /**
   * @param root string of the root name, assumed a valid note
   * @returns string of the root, which may be the alternative note looked up in SCALES.MAJORS
   */
  const remapMajorRoot = function( root ) {
    // if the root points to an alternative note
    if ( typeof SCALES.MAJORS[ root ] == 'string' ) {
      // return the alternative note
      return SCALES.MAJORS[ root ];
    }
    return root;
  }

  /**
   * @param root string of the root name, assumed a valid note
   * @returns string of the root, which may be the alternative note looked up in SCALES.MINOR_REMAP
   */
   const remapMinorRoot = function( root ) {
    if ( root in SCALES.MINOR_REMAP ) {
      return SCALES.MINOR_REMAP.root;
    }
    return root;
  }

}