export function FontSizeChanger() {

  this.size = 100; // percent
  let SIZES = [ 60, 70, 80, 90, 100, 110, 120, 130, 140, 150 ];

  let doms = {
    root : document.createElement( null ),
    wrapper : document.createElement( null ),
    up : document.createElement( null ),
    down : document.createElement( null ),
  }

  this.init = function( rootDom, onClick ) {
    doms.root = rootDom;
    createDoms( onClick );
    return this;
  };

  this.render = function() {
    doms.root.innerHTML = '';
    doms.wrapper.innerHTML = '';
    doms.wrapper.appendChild( doms.down );
    doms.wrapper.appendChild( doms.up );
    doms.root.appendChild( doms.wrapper );
  }

  const createDoms = function( onClick ) {
    doms.wrapper = createWrapper();
    doms.up = createButton( 'up', onClick );
    doms.down = createButton( 'down', onClick );
  };

  const createWrapper = function () {
    let wrapper = document.createElement( 'div' );
    wrapper.className = 'font-size-changer';
    return wrapper;
  }

  const createButton = ( step, onClick ) => {
    let sign = ( step == 'up' ) ? '+' : '-';
    let button = document.createElement( 'button' );
    button.className = step + ' btn';
    button.innerText = 'T' + sign;
    button.addEventListener(
      'click',
      event => {
        // get next size (up or down)
        let toSize = this.stepSize( step );
        onClick( event, toSize );
      }
    );
    button.addEventListener(
      'mousedown',
      event => false
    );
    button.addEventListener(
      'selectstart',
      event => false
    );
    return button;
  };

  // @param step string (up|down)
  this.stepSize = function( step ) {
    let sizeIndex = SIZES.indexOf( this.size );
    let next = sizeIndex;
    let isTop = false;
    let isBottom = false;

    switch ( step ) {
      case 'up': {
        if ( sizeIndex < SIZES.length-1 ) {
          next = sizeIndex + 1;
        }
        isTop = next == SIZES.length-1;
        break;
      }
      case 'down': {
        if ( sizeIndex > 0 ) {
          next = sizeIndex - 1;
        }
        isBottom = next == 0;
        break;
      }
    }

    this.size = SIZES[ next ];

    doms.up.classList.remove('end');
    doms.down.classList.remove('end');

    isTop && doms.up.classList.add('end');
    isBottom && doms.down.classList.add('end');

    return this.size;
  }

}