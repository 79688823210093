import { Key } from "./key";
import { ScaleLookup as SCALES } from "./scale-lookup";

export function KeySelect() {

  this.key = new Key();

  var doms = {
    root : document.createElement( null ),
    wrapper : document.createElement( null ),
    select : document.createElement( null ),
    up : document.createElement( null ),
    down : document.createElement( null ),
  };

  /**
   * 
   * @param rootDom dom of the existing container to hold this tool
   * @param key Key object, it defines the quality(major|minor) of the select list and the selected key symbol
   * @param selectOnChange callback function for the onChange event of the <select>
   */
  this.init = function( rootDom, key, onChange ) {
    doms.root = rootDom;
    this.key = key;
    createDoms( key, onChange );
    return this;
  };

  this.changeKey = function( toSymbol ) {
    this.key = new Key().init( toSymbol );
  };

  // @param step (up|down)
  this.stepKey = function( step ) {
    let roots = getRoots();
    let rootIndex = roots.indexOf( this.key.symbol );
    let newKey = new Key();

    if ( rootIndex == -1 ) return newKey;
    let delta = ( step == 'up' ) ? 1 : -1;

    let newIndex = ( rootIndex + delta + roots.length ) % roots.length;
    let newSymbol = roots[ newIndex ];
    this.key = newKey.init( newSymbol );

    return newKey;
  }

  this.render = function() {
    doms.select.value = this.key.symbol;
    doms.root.innerHTML = '';
    doms.wrapper.innerHTML = '';
    doms.wrapper.appendChild( doms.select );
    doms.wrapper.appendChild( doms.up );
    doms.wrapper.appendChild( doms.down );
    doms.root.appendChild( doms.wrapper );
  };

  const createDoms = function ( key, onChange ) {
    // set doms to object private property
    doms.wrapper = createWrapper();
    doms.select = createSelect( key, onChange );
    doms.up = createButton( 'up', onChange );
    doms.down = createButton( 'down', onChange );
  };

  const createWrapper = function () {
    let wrapper = document.createElement( 'div' );
    wrapper.className = 'key-select';
    return wrapper;
  }

  /**
   * @param key Key object, the selected key
   * @param onClick callback function( event, toSymbol ) on button click
   * @returns dom of the newly created <select>
   */
   const createSelect = ( key, selectOnChange ) => {
    let select = document.createElement( 'select' );
    select.className = 'form-select';
    
    // insert <option>
    getRoots().forEach(
      root => {
        let option = document.createElement( 'option' );
        let symbol = root;
        // set minor symbol
        if ( key.quality == 'minor' ) symbol += 'm';
        option.value = symbol;
        option.innerText = symbol;
        select.appendChild( option );
      }
    );

    // set selected valute
    select.value = key.symbol;
    // set onChange event
    select.addEventListener(
      'change',
      event => {
        let toSymbol = event.target.value;
        this.changeKey( toSymbol );
        selectOnChange( event, toSymbol );
      }
    );

    return select;
  };

  /**
   * @param step string (up|down)
   * @param onClick callback function( event, toSymbol ) on button click
   * @returns dom of the newly created <button>
   */
  const createButton = ( step, onClick ) => {
    let button = document.createElement( 'button' );
    button.type = 'button';
    button.className = `step ${step} btn btn-default`;

    // set events
    button.addEventListener(
      'click',
      event => {
        // get next root (up or down)
        let toKey = this.stepKey( step );
        onClick( event, toKey.symbol )
      }
    );

    return button;
  };

  // @param step (up|down)
  const getSiblingRoot = function( step ) {
    
  }
  const getRoots = quality => (quality == 'minor') ? SCALES.MINOR_ROOTS : SCALES.MAJOR_ROOTS;

}