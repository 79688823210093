/**
 * 
 * PURPOSE
 * Build mobile friendly and font-size scalable chord charts.
 * Tackle the mobile screen wrapping problem of chord charts.
 * 
 * HOW
 * Convert a string of chords and a string of words into arrays of dom objects
 * - Parsing a line of word string.
 * - Parsing a line of chord string, with chords align to the words.
 * - Creating dom containers for each word.
 * - Creating dom containers that hold one or more chords.
 * - So that the the doms retain the alignment even after line wrapping.
 * 
 * MAPS
 * maps.chords = [
 *   {
 *     symbol: 'A',
 *     chord: new Chord(),
 *     start: 0, // starting position index. to remove in the end
 *     end: 9, // ending position index. to remove in the end
 *     anchorAt: [0,0], // anchor chord on [word index, offset position]
 *     mod: 'Bb', // modulated chord symbol
 *     modWidthDelta: 1 // change of word width after modulation
 *   },
 *   { ... },
 * ]
 * maps.words = [
 *   {
 *     word: 'Hello',
 *     start: 0, // starting position index. to remove in the end
 *     end: 5, // ending position index. to remove in the end
 *     pad: 3, // number of trailing space
 *     pre: 0, // number of leading space
 *     room: 8, // total space of the container to hold this word
 *     modWidthDelta: 0 // change for the room needed after chord modulation. The final container space will be room+modWidthDelta
 *   }
 * ]
 * 
 * EXAMPLE
 * 
 * A5 Bmaj7          G#7sus4 F#m     Cm7 D13b9  E
 * Hello world, with an   elephant long  tail
 * 
 * (note: to pad leading space, add a period as the first character)
 * (eg
 * .     A5    Bmaj7
 * Hello World
 * )
 * 
 * STEP 1: Anchor the chords to the words:
 * chords = 'A  Bmaj7          G#7sus4 F#m     Cm7 D13b9  E5'
 *           |  |              |       |       |   |
 *           1  2              3       4       5   6
 * words  = 'Hello world, with an__ elephant long_ tail ~~~~'
 *           \                    \                      \ add ghost word with ~ for the E5 chord to anchor on
 *            \                    \
 *             \                    \ code will pad these spaces with _, so the space between words is just one character
 *              \
 *               \| if there is leading space, code will pad with %
 *                | eg. '%%%Hello world, ...'
 * 
 * - words = word.split(' ')
 *
 * Anchors:
 * - chord 1 @ words[0][0]
 * - chord 2 @ words[0][3]
 * - chord 3 @ words[3][0]
 * - chord 4 @ words[4][3]
 * - chord 5 @ words[5][2]
 * - chord 6 @ words[6][0]
 * - chord 7 @ words[7][2]
 *
 * maps.words = [
 *    { word: 'Hello',    start: 0,  end: 5,  pad: 0, room: 5 }
 *   ,{ word: 'world',    start: 6,  end: 12, pad: 0, room: 6 }
 *   ,{ word: 'with',     start: 13, end: 17, pad: 0, room: 4 }
 *   ,{ word: 'an',       start: 18, end: 20, pad: 2, room: 4 } // pad:2 is the __ after 'an'
 *   ,{ word: 'elephant', start: 23, end: 31, pad: 0, room: 8 }
 *   ,{ word: 'long',     start: 32, end: 36, pad: 1, room: 5 }
 *   ,{ word: 'tail',     start: 38, end: 42, pad: 0, room: 4 }
 *   ,{ word: '~~~~',     start: 43, end: 47, pad: 0, room: 4 }
 * ]
 * 
 * maps.chords = [
 *    { symbol: 'A',       start: 0,  end: 1,  anchor: [0,0] }
 *   ,{ symbol: 'Bmaj7',   start: 3,  end: 8,  anchor: [0,3] }
 *   ,{ symbol: 'G#7sus4', start: 18, end: 25, anchor: [3,0] }
 *   ,{ symbol: 'F#m',     start: 26, end: 29, anchor: [4,3] }
 *   ,{ symbol: 'Cm7',     start: 34, end: 37, anchor: [5,2] }
 *   ,{ symbol: 'D13b9',   start: 38, end: 43, anchor: [6,0] }
 *   ,{ symbol: 'E5',      start: 45, end: 47, anchor: [7,2] }
 * ]
 * 
 * STEP 2: EXPAND WORD SPACING TO FIT CHORD (using ^ in this example)
 * chords = 'A  Bmaj7             G#7sus4    F#m     Cm7 D13b9   E5'
 *           |  |                 |          |       |   |
 *           1  2                 3          4       5   6
 * words  = 'Hello^^^ world, with an__^^^ elephant long_ tail^ ~~~~'
 * 
 * maps.words = [ // start & end are no longer needed after anchoring was found
 *    { word: 'Hello',    pad: 3 } // anchors for word[0] end:8 - start:0 = width:8. 'Hello'.width=5, so pad 3 to fit width:8
 *   ,{ word: 'world',    pad: 0 } // anchors for word[1] does not exist, so no padding.
 *   ,{ word: 'with',     pad: 0 } // anchors for word[2] does not exist, so no padding.
 *   ,{ word: 'an',       pad: 5 } // anchors for word[3] end:25 - start:18 = width:7. 'an'.width=2, so pad 5 to fit width:7
 *   ,{ word: 'elephant', pad: 0 } // anchors for word[4] end:29 - start:26 = width:3 + anchor head space 3 ('   ' before F#m) => total width=6. 'elephant'.width=8 is enough to fit 6. So no padding.
 *   ,{ word: 'long',     pad: 1 } // anchors for word[5] end:37 - start:34 = width:3 + anchor head space 2 ('  ' before Cm7) => total width=5. 'long'.width=4. So pad 1 to fit width:5.
 *   ,{ word: 'tail',     pad: 1 } // anchors for word[6] end:43 - start:38 = width:5. 'tail'.width=4, so pad 1 to fit width:5
 *   ,{ word: '~~~~',     pad: 0 } // anchors for word[7] end:47 - start:45 = width:2 + anchor head space 2 ('  ' before E5) => total width=4. '~~~~'.width=4 is enough to fit 4. So no padding.
 * ]
 *
 * READY TO RENDER WORD CONTAINERS (indicated by @ below. remove padding symbols)
 * This will ensure wrapping of word and wrapping of chords will behave exactly the same
 * @@@@@@@@ @@@@@@ @@@@ @@@@@@@ @@@@@@@@ @@@@@ @@@@@ @@@@
 * A  Bmaj7             G#7sus4    F#m     Cm7 D13b9
 * Hello    world, with an      elephant long  tail    E5
 * @@@@@@@@ @@@@@@ @@@@ @@@@@@@ @@@@@@@@ @@@@@ @@@@@ @@@@
 * 
 * 
 * FOR KEY MODULATION, eg, up half step
 * maps.chords = [
 *    { symbol: 'A',       start: 0,  end: 1,  anchor: [0,0], mod: 'Bb',     modWidthDelta: 1  } // from A to Bb, width up 1
 *   ,{ symbol: 'Bmaj7',   start: 3,  end: 8,  anchor: [0,3], mod: 'Cmaj7',  modWidthDelta: 0  } // from Bmaj7 to Cmaj7, width unchanged
 *   ,{ symbol: 'G#7sus4', start: 18, end: 25, anchor: [3,0], mod: 'A7sus4', modWidthDelta: -1 } // from G#7sus4 to A7sus4, width down 1
 *   ,{ symbol: 'F#m',     start: 26, end: 29, anchor: [4,3], mod: 'Gm',     modWidthDelta: -1 } // ditto
 *   ,{ symbol: 'Cm7',     start: 34, end: 37, anchor: [5,2], mod: 'C#m7',   modWidthDelta: 1  }
 *   ,{ symbol: 'D13b9',   start: 38, end: 43, anchor: [6,0], mod: 'D#13b9', modWidthDelta: 1  }
 *   ,{ symbol: 'E5',      start: 45, end: 47, anchor: [7,2], mod: 'F5',     modWidthDelta: 0  }
 * ]
 * 
 * - This will adjust the word spacing.
 * - Eg, for word[0] 'Hello', the total modWidthDelta = 1+0 = 1, so when fitting the word, one more space to pad:
 *   { word: 'Hello', pad: 3, modWidthDelta: 1 } // ==> effective pad=4
 * - Eg, for word[3] 'an', the total modWidthDelta = -1, so when fitting the word, one less space to pad:
 *   { word: 'an', pad: 5, modWidthDelta: -1 } // ==> effective pad=4
 */

import { Chord } from "./chord";
import { Key } from "./key";

export function Line() {
  // let chordString  = 'A  Bmaj7          G#7sus4 F#m     Cm7 D13b9  E5';
  // let wordString   = 'Hello world, with an   elephant long  tail';

  let strings = {
    chords : '',
    words : '',
  }

  let doms = {
    lineRoot : document.createElement(null),
    chordsParent : document.createElement(null),
    wordsParent : document.createElement(null),
    chords : [],
    words: [],
  }

  let maps = {
    chords : [],
    words : [],
  }

  this.originalKey = new Key();

  /**
   * @param originalKey, Key() object to set the context for the original chords
   */
  this.init = function( lineDom, originalKey ) {

    this.originalKey = originalKey;
    let { chordsParent, wordsParent } = getChordsAndWordsParentDoms( lineDom );

    // clean string & store
    strings.chords = cleanString( htmlDecode( chordsParent.innerHTML ) );
    strings.words = cleanString( htmlDecode( wordsParent.innerHTML ) );

    // map out the words & chords
    maps.chords = mapChords( strings.chords, this.originalKey );
    maps.words  = mapWords( strings.words, strings.chords );

    // anchors chords onto words
    anchorChords();

    // expands word space to fit chords
    fitChords();

    let built = buildDoms();

    doms = {
      lineRoot : lineDom,
      chordsParent,
      wordsParent,
      chords : built.chords,
      words : built.words,
    };

    return this;
  };

  /**
   * @param toKey object of Key, the target key to transpose to
   * @returns void. doms is modified.
   */
  this.transpose = function( toKey ) {

    if ( typeof toKey === 'undefined' || toKey.root == '' ) return this;

    maps.chords.forEach(
      ( one, index ) => {
        // get the new transposed chord
        let modChord = one.chord.transpose( toKey );
        // set it to the chord map
        one.mod = modChord.symbol;
        // adjust the spacing fo this chord change
        if ( one.mod != '' ) {
          one.modWidthDelta = one.mod.length - one.symbol.length;
        }
      }
    );

    // set modWidthDelta for each word, to fit the chord changes
    maps.words.forEach(
      ( one, index ) => {
        let chordString = getChordStringOfWord( index );
        // get the sum of width-delta of all the anchoring chords
        let chordsDelta = getChordsDeltaSumOfWord( index );

        // if chord string expanded
        if ( chordsDelta > 0 ) {
          // set if extra room needed
          one.modWidthDelta = Math.max( chordString.length - one.room, 0);
        // if chord string contracted
        } else if ( chordsDelta < 0 ) {
          // eat into the trailing pad
          one.modWidthDelta = Math.max( -one.pad, chordsDelta );
        // if no change
        } else {
          one.modWidthDelta = 0;
        }
      }
    );

    // rebuild the doms
    let { chords, words } = buildDoms();
    doms.chords = chords;
    doms.words = words;

    // return this object for chainability
    return this;
  }

  /**
   * transpose key, insert chord & word doms into there parent doms, fit spacing
   * @param toKey Key object, the key to transpose to
   */
  this.render = function( /* toKey */ ) {

    let { lineRoot, chordsParent, wordsParent } = doms;

    // if to transpose
    // if ( typeof toKey !== 'undefined' && toKey.root != '' ) {
    //   this.transpose( toKey );
    // }

    // check if spaces needed after each word
    var spaceNeeded = needSpaceAfterWord( doms.chords, doms.words );

    // clear chordDom
    chordsParent.innerHTML = '';
    // insert doms of chords
    doms.chords.forEach(
      (x, index) => {
        chordsParent.appendChild( x );
        spaceNeeded[index] && chordsParent.appendChild( createSpaceNode() );
      }
    );

    // clear wordDom
    wordsParent.innerHTML = '';
    // insert doms of words
    doms.words.forEach(
      (x, index) => {
        wordsParent.appendChild( x );
        spaceNeeded[index] && wordsParent.appendChild( createSpaceNode() );
      }
    );

    // add linebreak to help copy-and-paste formatting
    wordsParent.appendChild( createLineBreak() );

    // handle chordless
    if ( !domHasChords( doms.chords ) ) {
      lineRoot.classList.add('chordless');
    }
    
    // handle wordless
    if ( !domHasWords( doms.words ) ) {
      lineRoot.classList.add('wordless');
    }

  }

  /**
   * Get dom objects chordsParent & wordsParent from given lineDom.
   * Make them up in lineDom if not found.
   * @param {*} lineDom 
   * @returns 
   */
  const getChordsAndWordsParentDoms = function( lineDom ) {
    let chordsParent = lineDom.querySelector('.chords');
    let wordsParent = lineDom.querySelector('.words');

    // make up dom if not found
    if ( chordsParent == null ) {
      chordsParent = document.createElement('div');
      chordsParent.className = 'chords';
      lineDom.insertBefore( chordsParent, wordsParent );
    }
    // make up dom if not found
    if ( wordsParent == null ) {
      wordsParent = document.createElement('div');
      wordsParent.className = 'words';
      chordsParent.after( wordsParent );
    }

    return {
      chordsParent,
      wordsParent,
    }
  }
  /**
   * Trim surrounding whitespaces. Then replace with a space the first character that is non-letter.
   * The purpose is to allow using a symbol to reserve leading spaces.
   * Eg, '   . A B  C. D    '
   *  =>    '  A B  C. D'
   * @returns string of cleaned string
   */
  const cleanString = function ( string ) {
    let _string = string.trim();
    // replace first character with space if it's a non-letter
    // _string = _string.replace(/^[^A-zÀ-ú]/, ' ');
    // replace first character with space if it's a period
    _string = _string.replace(/^\./, ' ');
    return _string;
  }

  /**
   * Replace multi spaces with _, but preserving the last one so that all words are separated by only one space.
   * Leading space of the whole string is replaced by %
   * eg, '  aa bb   cc'
   *  => '%%aa bb__ cc'
   */
  const prefillSpaces = function ( string ) {
    let _string = string
      // replace all spaces with _
      .replace(/ /g, '_')
      // change the last _ back to space
      .replace(/(_)([^_])/g, ' $2');

    // fix leading spaces
    if ( _string[0] == ' ' || _string[0] == '_') {
      _string = _string.replace(/ /, '_');
      // replace leading _'s with %'s
        // 1. count leading _
      var leadingCount = /_[^_]/.exec( _string ).index + 1;
        // 2. do replace with %
      var aText = _string.split('');
      aText.splice(0, leadingCount, repeatString('%', leadingCount));
      _string = aText.join('');
    }

    return _string;
  }

  /**
   * Add trailing ghost word to hold dangling chords
   * eg, ~~~~~~ is the ghost word
   *     A2 Bm7   C#aug
   *     I  wish ~~~~~~
   * @returns wordString with ghost word appended
   */
  const addGhostWord = function ( chordString, wordString ) {
    let _wordString = wordString;

    if ( chordString.length > wordString.length ) {
      let overflowedChordString = chordString.substr( wordString.length );
      // if there is a dangling chord
      if ( overflowedChordString.indexOf(' ') > -1 ) {
        // add ghost word
        _wordString += ' ' + repeatString( '~', overflowedChordString.length - 1 );
      }
    }

    return _wordString;
  }

  /**
   * @param wordString, string of words prepared by cleanString
   * @returns arrays of objects containing the word strings, their positions and paddings
   * eg, {
   *   word: 'Hello',
   *   pre: 0, // pre-padding, ie, leading space
   *   pad: 3, // padding, ie, trailing space
   *   room: 8 // width (number of characters) of the dom container to fit the chord(s)
   * }
   */
  const mapWords = function( wordString, chordString ) {

    // refill multi spaces as (N-1)x'_' + ' ', so words are only separated by one space
    let _wordString = prefillSpaces( wordString );
    _wordString = addGhostWord( chordString, _wordString );

    let words = _wordString.split(' ');
    let lastPos = 0;
    return words.map(
      word => {
        let start = lastPos;
        let end = start + word.length;
        let pad = 0; // trailing padding
        let pre = 0; // leading padding
        let room = 0; // total space needed, = pre + word.length + pad

        // count trailing _ as the pad count
        let match = /_+/.exec( word );
        if ( match ) {
          pad = match[0].length;
        }

        // count leading % as the prepad count
        match = /%+/.exec( word );
        if ( match ) {
          pre = match[0].length;
        }

        // remove the % & _ paddings and ~ ghost words, now that the spacings are counted
        word = word.replace(/[%_~]/g, '');

        // count the room needed
        room = pre + word.length + pad;

        // '+ 1' is to include the trailing space
        lastPos = end + 1;
        return {
          word,
          start,
          end,
          pad,
          pre,
          room,
          modWidthDelta : 0,
        }
      }
    );
  
  }

  /**
   * @param string  string of chords with preset spacings
   * @param originalKey, Key() object to set the context for the chords
   * @returns arrays of objects containing the chord strings and their anchoring position on a word
   * eg, {
   *   word: 'Am7',
   *   anchor: [3,4] // meaning words[3], offset 4
   * }
   */
  const mapChords = function( string, originalKey ) {
    // go over one character of the string at a time
    let aChord = string.split('');
    let lastStart = 0;
    let lastChar = '';
    let chordMap = aChord.map(
      ( char, index ) => {
        // indentify the start and end positions of a chord
        let isStart = false;
        let isEnd = false;
        let isEndOfLine = false;

        // for the beginning of the string
        if ( index == 0 ) {
          isStart = true;
        } else {
          // if starting a letter after a space
          if ( char != ' ' && lastChar == ' ' ) {
            isStart = true;
          // if starting a space after a letter
          } else if ( char == ' ' && lastChar != ' ' ) {
            isEnd = true;
          }
        }

        // it's the end of the string
        if ( aChord.length == index+1 ) {
          isEnd = true;
          isEndOfLine = true;
        }
  
        // save the char for next loop
        lastChar = char;
  
        // mark as last chord start position
        if ( isStart ) {
          lastStart = index;
        }

        // at the end of a chord
        if ( isEnd ) {
          // return the chord and its postions
          let start = lastStart;
          let end = index + (isEndOfLine?1:0);
          let symbol = string.substring( start, end );
          let chord = new Chord().init( symbol, originalKey )
          return {
            symbol,
            chord,
            start,
            end,
            anchorAt : [0, 0],
            mod : '',
            modWidthDelta : 0,
          }
        }
      }
    );
    // remove null from array
    chordMap = chordMap.filter( n => n );
    return chordMap;
  }

  /**
   * Anchor chords on the words, by finding the location of the chords on the word map
   * @returns null
   */
  const anchorChords = function() {
    maps.chords.forEach(
      one => {
        let anchorAt = [ 0, 0 ];
        // loop through the words to see which word the chord can anchor onto
        for ( let [ index, word ] of maps.words.entries() ) {
          // once the starting-index of the chord enters the word's range (between word.start and word.end)
          if ( one.start >= word.start && one.start < word.end ) {
            // the anchor is found
            // eg, [ 2, 5 ] means anchoring on words[2] at character 5 (index starts from 0)
            anchorAt = [ index, one.start - word.start ];
            break;
          // if the chord starts on a space, ie, the end of the current word
          } else if ( one.start == word.end ) {
            // then anchor it to the next chord, offset it by -1
            anchorAt = [ index+1, -1 ];
            break;
          }
        }
        one.anchorAt = anchorAt;
      }
    );
  }

  /**
   * Expand the word spacing to fit the chords in
   * NOTE: it's done in the original key BEFORE transposing,
   *   therefore modWidthDelta is not considered here.
   * @returns null
   */
  const fitChords = function() {
    for ( let [ index, word ] of maps.words.entries() ) {
      let chordOnWord = -1;
      let chordOffset = -1;
      let firstStart = -1;
      let lastEnd = -1;
      let firstOffset = -1;
      let chordRoom = 0;
      let neededRoom = 0;
      let isFirstChordOnSpace = false;
      let chordIndexOnWord = -1;

      for ( let one of maps.chords ) {

        // record the index of the word that the chord is anchoring on
        chordOnWord = one.anchorAt[0];
        chordOffset = one.anchorAt[1];

        // for the chord that anchors on this word
        if ( chordOnWord == index ) {

          chordIndexOnWord++;
  
          // check if the first chord anchors on a space, ie, the offset is -1
          if ( chordIndexOnWord == 0 ) {
            isFirstChordOnSpace = chordOffset == -1;
          }

          if ( isFirstChordOnSpace ) {
            // console.log( 'yes', word, chord );
            // re-reference all chords' offset, shifting right by 1
            one.anchorAt[1]++;
          }

          // record positions of the first chord
          if ( chordIndexOnWord == 0 ) {
            firstStart = one.start;
            firstOffset = chordOffset;
          }
          lastEnd = one.end;

        } else if ( chordOnWord > index ) {
          break;
        }
      }
      // calc the space needed for the chord(s) that anchors on this word
      chordRoom = lastEnd - firstStart + firstOffset;

      // compare with the room needed for the word, pick the larger one as the real room needed
      neededRoom = Math.max( chordRoom, word.room );

      // console.log(word.word, lastEnd, -firstStart, firstOffset, 'sum=', chordRoom, 'needed=', neededRoom );

      // for chord anchoring on a space
      if ( isFirstChordOnSpace ) {
        // add more room
        neededRoom++;
        word.pre++;
      }

      // set real room & padding
      word.room = neededRoom;
      word.pad = neededRoom - ( word.pre + word.word.length );

    }

    // delete the now outdated word positions
    maps.words.map( x => {
      delete x.start;
      delete x.end;
    });

    // delete the now outdated chord positions
    maps.chords.map( x => {
      delete x.start;
      delete x.end;
    });

  }

  /**
   * Build dom for chords and words
   * @returns { chordDomContainer, wordDomContainer }
   */
  const buildDoms = function() {

    let wordDoms = [];
    let chordDoms = [];

    // let chordDomContainer = document.createElement('div');
    // chordDomContainer.className = 'chord-line';

    maps.words.forEach(
      (one, index) => {

        // create dom for word
        let el = document.createElement('span');
        let room = one.room + one.modWidthDelta;
        el.className = `word room-${room}`;
        el.textContent = repeatString(' ', one.pre) + one.word + repeatString(' ', one.pad + one.modWidthDelta);
        // add the container
        wordDoms.push( el );

        // get chords of this word
        let chordStringOfWord = getChordStringOfWord( index );

        // pad trailing space
        chordStringOfWord += repeatString(' ', room - chordStringOfWord.length);

        // create dom for chord
        let el2 = document.createElement('span');
        el2.className = `chord room-${room}`;
        el2.textContent = chordStringOfWord;
        chordDoms.push( el2 );

      }
    );

    return {
      chords: chordDoms,
      words:  wordDoms,
    };

  }

  const needSpaceAfterWord = function( chordDoms, wordDoms ) {
    return wordDoms.map(
      (x, index) => {
        let hasPad = x.innerHTML.split('').pop() == ' ';
        let nextChordStartsWithPrintable = (
          typeof chordDoms[index+1] != 'undefined'
          && chordDoms[index+1].innerHTML.charAt(0) != ' '
        );
        return !hasPad || nextChordStartsWithPrintable;
      }
    );
  }

  const domHasChords = function( chordDoms ) {
    let concatChords = chordDoms.reduce(
      ( previous, current ) => {
        return previous + current.innerHTML.replace(/ /g, '');
      }
      , ''
    );
    return concatChords != '';
  }

  const domHasWords = function( wordDoms ) {
    let concatWords = wordDoms.reduce(
      ( previous, current ) => {
        return previous + current.innerHTML.replace(/ /g, '');
      }
      , ''
    );
    return concatWords != '';
  }

  const getChordsOfWord = function( wordIndex ) {
    return maps.chords.filter(
      x => x.anchorAt[0] == wordIndex
    );
  }

  // write string of chords for the word
  const getChordStringOfWord = function( wordIndex ) {
    return getChordsOfWord( wordIndex ).reduce(
      ( previous, current, index ) => {
        // count space: chordOffset is absolute position, deduct the space used by the chords before
          // ie, chordOffset - previous.length
        let spaceLength = current.anchorAt[1] - previous.length;
          // if there no space between chords, due to chord change
        if ( index > 0 && spaceLength == 0 ) {
          // force 1 space in between
          spaceLength = 1;
        }
        let space = repeatString(' ', spaceLength);
        let symbol = ( current.mod != '' ) ? current.mod : current.symbol;
        return previous + space + symbol;
      }
      ,''
    );
  }

  const getChordsDeltaSumOfWord = function( wordIndex ) {
    return getChordsOfWord( wordIndex ).reduce(
      ( previous, current ) => {
        return previous + current.modWidthDelta;
      }
      ,0
    );
  }

  const repeatString = function( string, count ) {
    // make sure count is not negative
    let _count = Math.max( count, 0 );
    return string.repeat( _count );
  }

  const createLineBreak = () => {
    let linebreak = document.createElement('div');
    linebreak.className = 'line-break';
    linebreak.textContent = ' ';
    return linebreak;
  };

  const createSpaceNode = () => document.createTextNode(' ');
  
  const htmlDecode = input => new DOMParser().parseFromString(input, "text/html").documentElement.textContent;

}