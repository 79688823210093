/**
 * Convert lines of a chord chart into dom structures, for mobile-friendly wrapping
 * 
 * Expect given dom structure:
 * <div class="chord-chart" data-key-signature="C">
 *  <div class="line">
 *    <div class="chords">  ... chords aligning with the words ... </div>
 *    <div class="words">  ... words in monospace fonts ... </div>
 *  </div>
 *  <div class="line">
 *    ... repeat
 *  </div>
 *  <!-- modulate to D major. The hardcoded chords here are in the context of D -->
 *  <div class="line" data-modulation="D">
 *    ... repeat
 *  </div>
 *  <!-- continue in D major -->
 *  <div class="line">
 *    ... repeat
 *  </div>
 * </div>
 */
import { Key } from './key';
import { Chord } from './chord';
import { KeySelect } from './key-select';
import { FontSizeChanger } from './font-size-changer';
import { Line } from './line'; 

export function ChordChart() {

  let doms = {
    chordChart : document.createElement( null ),
    bodies : [], // type nodeList
    lines : [],  // type nodeList
    tools : {
      transposer : document.createElement( null ),
      fontSizeChanger : document.createElement( null ),
    }
  };
  let objects = {
    originalKey : new Key(),
    toKey : new Key(),
    lines : [],
    transposer : new KeySelect(),
    fontSizeChanger : new FontSizeChanger(),
  };

  /**
   * @param chordChartDom the dom with className 'chord-chart', containing the lines of chords and words
   */
  this.init = function( chordChartDom ) {
    // require class="chord-chart"
    if ( !chordChartDom.classList.contains('chord-chart') ) return this;

    doms.chordChart = chordChartDom;
    queryDoms( chordChartDom );

    if ( 'keySignature' in chordChartDom.dataset ) {
      objects.originalKey.init( chordChartDom.dataset.keySignature );
    }

    initLines();
    initKeySelect();
    initFontSizeChanger();

    return this;
  }

  /**
   * set objects.lines AFTER both doms.chordChart and objects.originalKey are set
   */
  const initLines = function() {
    let key = objects.originalKey;
    objects.lines = doms.lines.map(
      lineDom => {
        // handle modulation
        if ('modulation' in lineDom.dataset) {
          key = new Key().init( lineDom.dataset.modulation );
        }
        return new Line().init(
          lineDom,
          key
        )
      }
    );
  };

  const initKeySelect = function() {
    objects.transposer = new KeySelect().init(
      doms.tools.transposer,
      objects.originalKey,
      transposerOnChange
    );
  };

  const initFontSizeChanger = function() {
    objects.fontSizeChanger = new FontSizeChanger().init(
      doms.tools.fontSizeChanger,
      fontSizeOnChange
    );
  };

  const queryDoms = function( chordChartDom ) {
    doms.lines = [...chordChartDom.querySelectorAll('.line')];
    doms.bodies = chordChartDom.querySelectorAll('.body');
    doms.tools.transposer = chordChartDom.querySelector('.transposer');
    doms.tools.fontSizeChanger = chordChartDom.querySelector('.font-size-changer');
  };

  const transpose = function( toKey ) {
    // todo: handle modulations in a song
    objects.toKey = toKey;
    objects.lines.forEach(
      one => {
        // treat the line key (for modulation) as a chord
        let modChord = new Chord().init( one.originalKey.symbol, objects.originalKey );
        let newModChord = modChord.transpose( toKey );
        let newLineKey = new Key().init( newModChord.symbol );
        return one.transpose( newLineKey );
      }
    );
  };

  const changeFontSize = function( toSize ) {
    doms.bodies.forEach(
      body => body.style.fontSize = toSize + '%'
    )
  }

  const render = function() {
    objects.lines.forEach(
      one => one.render()
    );
    objects.transposer.render();
    objects.fontSizeChanger.render();
  };
  this.render = render;

  const transposerOnChange = ( event, toSymbol ) => {
    transpose( new Key().init( toSymbol ) );
    render();
  }

  const fontSizeOnChange = ( event, toSize ) => {
    changeFontSize( toSize );
  }

}