import { ChordChart } from './chord-chart/chord-chart';

export function SitePad() {

  // check OS dark theme
  const IS_DARK_THEME = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

  let doms = {
    body          : document.querySelector( 'body' ),
    chordCharts   : document.querySelectorAll( '.chord-chart' ),
    control : {
      darkTheme   : document.querySelector( '#dark-mode-switch' ),
      revealPosts : document.querySelectorAll( '.post .reveal-handle' ),
      transpose   : document.querySelectorAll( '.post .transpose' ),
    },
    youtubes      : document.querySelectorAll( 'iframe.youtube' ),
  };

  let objects = {
    chordCharts : [],
    youtubePlayers : [],
  };

  this.init = function() {

    initChordCharts();
    initYouTubes();
    renderChordCharts();

    runFunctionsIn( events );
    runFunctionsIn( syncs );

    return this;
  };

  const initChordCharts = function() {
    objects.chordCharts = [...doms.chordCharts].map(
      dom => new ChordChart().init( dom )
    );
  };

  const initYouTubes = function() {
    let tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    let firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    window.onYouTubeIframeAPIReady = () => {
      objects.youtubePlayers = [...doms.youtubes].map(
        ( iframe, index ) => {
          iframe.id = 'youtube-player-' + index;
          return new YT.Player(
            iframe.id,
            {
              events: {
                'onReady': onPlayerReady,
                'onStateChange': onPlayerStateChange,
              },
            }
          );
        }
      );
    };

    let onPlayerReady = event => {
      // event.target.playVideo();
    };
    let onPlayerStateChange = event => {
      if ( event.data == YT.PlayerState.PLAYING ) {
        objects.youtubePlayers
          // for all players except me
          .filter( x => x != event.target )
          // pause video
          .forEach( x => x.pauseVideo() );
      }
    };

  }

  const renderChordCharts = function() {
    objects.chordCharts.forEach( x => x.render() );
  }

  let events = {};
  events.darkTheme = () => {
    doms.control.darkTheme.addEventListener(
      'change',
      function( event ) {
        switchDarkTheme( event.target.checked );
      }
    )
  };
  events.revealPosts = () => {
    doms.control.revealPosts.forEach(
      x => x.addEventListener(
        'click',
        function( event ) {
          let post = event.target.closest('.post');
          let intro = post.querySelector( '.intro' );
          let isLess = intro.classList.contains('less');
          if ( isLess ) {
            intro.classList.remove('less');
          } else {
            intro.classList.add('less');
          }
        }
      )
    );
  };
  events.transpose = () => {
    doms.control.transpose.forEach(
      (x, i) => 
      x.addEventListener(
        'change',
        event => {
          // console.log(this);
          objects.chordCharts[i].toKey.init( event.target.value );
          objects.chordCharts[i].render();
        }
      )
    )
  };

  let syncs = {};
  syncs.DarkThemeToggle = function() {
    IS_DARK_THEME && ( doms.control.darkTheme.checked = true );
  }

  const switchDarkTheme = function( isOn ) {
    doms.body.classList.toggle( 'dark', isOn );
  }

  const runFunctionsIn = function( obj ) {
    for ( let i in obj ) {
      if (typeof obj[i] === 'function') {
        obj[i]();
      };
    }
  }

}