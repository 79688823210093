/**
 * MUSIC NOTE
 * eg, C, D, E, F#, Ab, ...
 * No double sharp or double flat for now.
 * 
 * code-symbol lookup
 * 0	C,B#
 * 1	C#,Db
 * 2	D
 * 3	D#,Eb
 * 4	E,Fb
 * 5	F,E#
 * 6	F#,Gb
 * 7	G
 * 8	G#,Ab
 * 9	A
 * 10	A#,Bb
 * 11	B,Cb
 */

import { Key } from "./key";

export function Note() {

  this.symbol = '';
  this.code = -1; // 0:C, 1:C#, ... 11:B
  this.accidental = '' // # or b. No double sharp or double flat for now.
  this.context = {
    key : new Key(),
    indexFromRoot : -1,
    indexOnScale : -1,
  };

  // there are 12 notes
  const BASE = 12;

  // A code represents a music note. A code is the index of LOOKUP array
  const LOOKUP = [
    ['C','B#'],  // code 0
    ['C#','Db'], // code 1
    ['D'],       // code 2, etc...
    ['D#','Eb'],
    ['E','Fb'],
    ['F','E#'],
    ['F#','Gb'],
    ['G'],
    ['G#','Ab'],
    ['A'],
    ['A#','Bb'],
    ['B','Cb'],
  ];

  /**
   * @param noteSymbol string of music note like 'C', 'Bb', 'F#', etc...
   * @param key object
   */
  this.init = function( noteSymbol, key ) {
    let code = lookUpCode( noteSymbol );
    // return if invalid
    if ( code == -1 ) {
      // still try to set key, so slashed chord without note can still have a context, eg, '/G'
      this.setKey( key );
      return this;
    }

    this.symbol = noteSymbol;
    this.code = code;
    if ( code.length > 1 ) {
      this.accidental = code.slice(-1);
    }

    // set key if given
    key && this.setKey( key );
    
    return this;
  };

  /**
   * @param key object
   */
  this.setKey = function( key ) {
    if ( key.root == '' ) return;
    if ( this.code == -1 ) {
      // still try to set key, so slashed chord without note can still have a context, eg, '/G'
      this.context.key = key;
      return;
    }
    this.context = {
      key : key,
      indexFromRoot : baseCode( this.code - lookUpCode( key.root ) ),
      indexOnScale : key.scale.indexOf( this.symbol ),
    };

  };

  /**
   * @count integer, the number of halfSteps to transpose the note to. +ve=up, -ve=down
   * @preferredAccidental string '#', 'b' or ''
   * @returns a new Note object without a key set to the context
   */
  this.halfStep = function( count, preferredAccidental ) {
    let newNote = new Note();
    if ( this.symbol == '' ) return newNote;
    let newCode = baseCode( this.code + count );
    let newNoteName = getPreferredNote( LOOKUP[ newCode ], preferredAccidental );
    return newNote.init( newNoteName );
  }

  this.hasContext = () => (this.context.key.note.root != '');

  /**
   * eg, for [Ab, G#], if preferring #, return G#
   * @param candidates array of notes, eg, ['Ab', 'G#']
   * @param preferredAccidental string, '#', 'b' or ''
   * @returns string
   */
  const getPreferredNote = function( candidates, preferredAccidental ) {
    if ( candidates.length == 0 ) return '';
    let result = '';
    switch( preferredAccidental ) {
      case '': {
        result = candidates.find( x => x.length == 1 );
        break;
      }
      default: {
        result = candidates.find( x => x.slice(-1) == preferredAccidental );
      }
    }

    // if not found, just use the first note
    if ( !result ) result = candidates[0];

    return result;
  }

  /**
   * find the code of the given note. -1 if not found
   */
  const lookUpCode = function( note ) {
    let code = LOOKUP.findIndex(
      x => x.indexOf( note ) > -1
    );
    code = ( typeof code === 'undefined' ) ? -1 : code;
    return code;
  };

  /**
   * To base a code back to the 0-11 range (musical term "register").
   * It's used for transposing. Eg, 12=>0, 13=>1, 14=>2, -1=>11, -2=>10, etc...
   */
  const baseCode = function( code ) {
    // +BASE is to handle -ve code, to shift it back to the 0-11 range
    // 2nd %BASE is to handle code=-12 and multiples. Cos otherwise result = ( -12%12 +12 ) = 12.
    return ( code % BASE + BASE ) % BASE;
  }

}